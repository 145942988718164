import firebase from "firebase";
import platform from "platform";

console.log("from helpers", platform.name);

const firebaseConfig = {
  apiKey: "AIzaSyC3_rf5ECg2lwy5acrFrDCoU2l_v3d0WpU",
  authDomain: "livestage-4326e.firebaseapp.com",
  projectId: "livestage-4326e",
  storageBucket: "livestage-4326e.appspot.com",
  messagingSenderId: "536534825979",
  appId: "1:536534825979:web:ef7e5894e0f8a88118e95e",
  measurementId: "G-GB6EMVL4C6",
};

firebase.initializeApp(firebaseConfig);

export const getToken = () => {
  if (platform.name !== "IE" && platform.name !== "Safari") {
    const messaging = firebase.messaging();
    return messaging
      .getToken({
        vapidKey:
          "BLpzcNGqYcNAEZAQV9o_vm1Z4rHCKbPG0omIo8fVBytgRl-o57yapjVXbznEiYFKNg664Oa0KRqyxCdhllf9e5w",
      })
      .then((currentToken) => {
        if (currentToken) {
          console.log("current token for client: ", currentToken);
          // setTokenFound(true);
          // Track the token -> client mapping, by sending to backend server
          // show on the UI that permission is secured
          localStorage.setItem("fcmAdminToken", currentToken);
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
          // setTokenFound(false);
          // shows on the UI that permission is required
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
        // catch error while creating client token
      });
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    console.log("infireeeee");
    if (platform.name !== "IE" && platform.name !== "Safari") {
      const messaging = firebase.messaging();
      messaging.onMessage((payload) => {
        resolve(payload);
      });
    }
  });

export default firebase;
